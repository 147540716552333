<template>
  <div class="sai-tag-list" :class="{ sp: !$store.state.user.isSP }">
    <div class="sai-tag-list__title">{{ tagTitle }}</div>
    <div class="sai-tag-list__tags">
      <a
        class="sai-tag-list__tags__tag"
        :class="fillTagColor && 'fillColor'"
        v-for="(candidate, index) in filteredCandidateTags"
        v-bind:key="index"
        v-if="index < numberOfKeywordsDisplayed && candidate.tag"
        @click.prevent="selectTag(candidate)"
      >
        <span class="sai-tag-list__tags__tag__content">
          {{ convertTagText(candidate) }}
        </span>
      </a>
    </div>
    <div class="sai-tag-list__none" v-if="filteredCandidateTags.length === 0">
      <div class="sai-caption" v-html="noCandidateTagsMessage"></div>
    </div>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import TagListMixin from '@/mixins/TagListMixin';

@Component({})
export default class TagList extends mixins(TagListMixin) {
  get filteredCandidateTags() {
    if (this.selectedTagIds.length === 0) {
      return this.candidateTags;
    }
    return this.candidateTags.filter(t => {
      return this.selectedTagIds.indexOf(t.tag.id) === -1;
    });
  }
  // Use setShow for box
  setShow(boolean) {
    this.$emit('setShow', boolean);
  }
}
</script>

<style lang="scss" scoped>
@import '../style/component/tagList';
</style>
