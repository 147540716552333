<template>
  <div
    id="sai-box"
    ref="faqClientTag"
    class="FaqClientWindow mobile"
    :class="{ focus: show, withSaiModal: withModal }"
  >
    <div class="main">
      <div class="content" ref="searchTop">
        <div class="head" v-if="mainTitle">
          <div class="title">{{ mainTitle }}</div>
        </div>
        <div class="tags">
          <template v-if="withListOfKeywords">
            <a class="button" @click="toggleChoices" ref="tagChoice">
              <font-awesome-icon icon="list" />
            </a>
          </template>
          <div class="taggeInput" ref="taggedInput">
            <TaggedInput
              ref="inputSearch"
              :selectedTags="selectedTags.values()"
              :searchText="searchText"
              :inputEmpty="inputEmpty"
              :filteredItems="filteredItems"
              @onTagRemoved="removeSelectedTag"
              @onSearchTextChanged="setSearchText"
              @setShow="setTagSearchShow"
              @focus="closeTags"
            />
          </div>
        </div>
      </div>
      <template v-if="withListOfKeywords">
        <div
          class="choices"
          v-if="tagsShow && Object.keys(topTags).length > 0"
          :style="{
            height: `calc(100vh - 150px - ${tagChoiceTop}px - ${spClientHeaderHeight}px)`,
          }"
        >
          <TagChoice
            :topTags="topTags"
            :selectedTags="selectedTags.values()"
            @setShow="setTagSearchShow"
          ></TagChoice>
        </div>
      </template>
      <div
        class="recommend"
        v-show="$store.state.tagSearch.show"
        :style="{
          height: `calc(100vh - 150px - ${inputSearchHeight}px - ${spClientHeaderHeight}px)`,
        }"
      >
        <div ref="tagList">
          <TagList
            v-if="!inputEmpty || withInitRecommendArea1"
            :candidateTags="candidateTags"
            :tagListTop="tagListTop"
            :inputEmpty="inputEmpty"
            :searchText="searchText"
            @onSelectTag="addSelectedTag"
            @setShow="setTagSearchShow"
          />
        </div>
        <div
          class="autocomplete_header"
          v-show="!inputEmpty || withFrequentFaq"
        >
          <div class="title">{{ faqListTitle }}</div>
          <div class="search_result" v-if="!inputEmpty">
            <span class="count">{{ filteredItems.length }}</span>
            <span class="text">件ヒットしました</span>
          </div>
        </div>
        <div class="autocomplete" v-show="!inputEmpty">
          <Autocomplete
            :filteredItems="filteredItems"
            :noCandidateFaqMessage="noCandidateFaqMessage"
          />
        </div>
        <template v-if="withInitRecommendArea2 && withFrequentFaq">
          <div class="autocomplete" v-if="inputEmpty">
            <Frequent />
          </div>
        </template>
        <template v-if="withInitRecommendArea2 && withImageTags">
          <div class="sai-tag-categories" v-if="inputEmpty">
            <TagCategory
              v-for="imgTag in imageTags"
              :key="imgTag.index"
              :title="imgTag.title"
              :tags="imgTag.tags"
              @tagifyText="tagifyTextExact"
              @setShow="setTagSearchShow"
            />
          </div>
        </template>
        <div class="clearWrap">
          <a class="button" @click.prevent="setTagSearchShow(false)">
            <div class="icon close">
              <font-awesome-icon icon="times" />
            </div>
            <span>{{ closeRecommendTitle }}</span>
          </a>
        </div>
      </div>
      <template v-if="withModal">
        <div class="sai-tag-search-modal" ref="modal" v-show="show"></div>
      </template>
    </div>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import { eventBus } from '@/eventBus';
import FaqClientTagMixin from '@/mixins/FaqClientTagMixin';
import FaqClientTagBoxMixin from 'ptype/box/mixins/FaqClientTagBoxMixin';

@Component({})
export default class FaqClientWindow extends mixins(
  FaqClientTagMixin,
  FaqClientTagBoxMixin
) {
  searchTop = 0;

  async toggleChoices() {
    if (this.$store.state.user.isIphone && this.$store.state.onIME) {
      eventBus.$emit('toggleIme', false);
      return;
    }
    await this.setTagChoiceShow(!this.tagsShow);
  }
  setTagChoiceShow(show) {
    this.tagsShow = show;
    show && this.setTagSearchShow(false);
  }
  setSearchTop() {
    this.searchTop = this.$refs.searchTop.offsetHeight;
  }
  // close keyword list when input search focusing
  closeTags() {
    this.tagsShow = false;
  }
}
</script>
<style lang="scss" scoped>
@import '../style/component/faqClientWindow';
</style>
