import Vue from 'vue';
import Component from 'vue-class-component';
import { eventBus } from '@/eventBus';
import { Watch } from 'vue-property-decorator';

import TaggedInput from 'ptype/box/components/TaggedInput.vue';
import TagList from 'ptype/box/components/TagList.vue';
import Autocomplete from 'ptype/box/components/Autocomplete.vue';

let components = {
  TaggedInput,
  TagList,
  Autocomplete,
};

if (process.env.WITH_LIST_OF_KEYWORDS) {
  components.TagChoice = require('ptype/box/components/TagChoice.vue').default;
}
if (process.env.WITH_FREQUENT_FAQ) {
  components.Frequent = require('ptype/box/components/Frequent.vue').default;
}
if (process.env.WITH_IMAGE_TAGS) {
  components.TagCategory = require('ptype/box/components/TagCategory.vue').default;
}

@Component({
  props: {},
  components: components,
})
export default class FaqClientTagBoxMixin extends Vue {
  withListOfKeywords = process.env.WITH_LIST_OF_KEYWORDS;
  withInitRecommendArea1 = process.env.WITH_INIT_RECOMMEND_AREA1;
  withInitRecommendArea2 = process.env.WITH_INIT_RECOMMEND_AREA2;
  withFrequentFaq = process.env.WITH_FREQUENT_FAQ;
  withImageTags = process.env.WITH_IMAGE_TAGS;
  withModal = process.env.WITH_MODAL;

  tagChoiceTitle = this.$store.state.constObj.TAG_CHOICE_TITLE;
  closeRecommendTitle = this.$store.state.constObj.CLOSE_RECOMMEND_TITLE;

  // client header height with PC device
  pcClientHeaderHeight = this.$store.state.constObj.PC_CLIENT_HEADER_HEIGHT
    ? this.$store.state.constObj.PC_CLIENT_HEADER_HEIGHT
    : 0;
  // client header height with SP device
  spClientHeaderHeight = this.$store.state.constObj.SP_CLIENT_HEADER_HEIGHT
    ? this.$store.state.constObj.SP_CLIENT_HEADER_HEIGHT
    : 0;
  // height input search
  inputSearchHeight = 0;

  scrollContainer = 'body';
  scrollTopElement = '#sai-box';
  scrollSearchElement = '#sai-box-faq-area';
  scrollFaqElement = '#sai-box-top';
  scrollAnswerElement = '#sai-box-scenario-answer';

  tagsShow = false;
  tagChoiceTop = 0;
  recommendHeight = 350;
  resultsHeight = 0;
  frequentItems = [];

  // getter
  get show() {
    return this.$store.state.tagSearch.show || this.tagsShow;
  }
  get recommendMaxHeight() {
    return `${this.recommendHeight + this.resultsHeight}px`;
  }
  get imageTags() {
    return this.$store.state.constObj.imageTags;
  }
  get faqListTitle() {
    return this.inputEmpty
      ? this.$store.state.constObj.FREQUENT_FAQ_TITLE
      : this.$store.state.constObj.CANDIDATE_FAQ_TITLE;
  }

  // functions for box
  @Watch('filteredItems')
  onChangeFilteredItems() {
    if (this.inputEmpty) {
      this.resultsHeight = 0;
      return;
    }
    setTimeout(() => {
      this.updateRecommendHeight();
    }, 0);
  }
  updateRecommendHeight() {
    const results = document.getElementsByClassName('sai-box-result');
    let height = 0;
    for (let i = 0; i < results.length; i++) {
      height += results[i].clientHeight;
    }
    this.resultsHeight = height;
  }
  toggleChoices() {
    this.setTagChoiceShow(!this.tagsShow);
  }
  setTagChoiceShow(show) {
    show && this.setTagSearchShow(false);
    this.tagsShow = show;
  }
  async setTagSearchShow(show) {
    setTimeout(() => {
      this.$store.commit('tagSearch/setShow', show);
      show && this.setTagChoiceShow(false);
    }, 0);
  }
  handleTagList(e) {
    if (this.$refs.modal && this.$refs.modal.contains(e.target)) {
      this.setTagSearchShow(false);
      this.setTagChoiceShow(false);
    }
  }
  setTagChoiceTop() {
    this.tagChoiceTop = this.$refs.tagChoice
      ? this.$refs.tagChoice.offsetHeight
      : 0;
  }

  setInputSearchHeight() {
    this.inputSearchHeight = this.$refs.inputSearch.$el
      ? this.$refs.inputSearch.$el.offsetHeight
      : 0;
  }

  async tagifyTextExact(tagText) {
    await this.$store.dispatch('tagSearch/tagifyTextExact', tagText);
  }
  // Life cycle events
  async created() {
    eventBus.$on('setTagChoiceShow', () => {
      this.setTagChoiceShow();
    });
    eventBus.$on('updateRecommendHeight', () => {
      this.updateRecommendHeight();
    });
  }
  updated() {
    this.setTagChoiceTop();
    this.setInputSearchHeight();
  }
  mounted() {
    window.addEventListener('resize', () => {
      this.setTagChoiceTop();
      this.setInputSearchHeight();
    });
  }
  beforeDestroy() {
    window.removeEventListener('resize', () => {
      this.setTagChoiceTop();
      this.setInputSearchHeight();
    });
  }
}
