<template>
  <div class="FaqClientWrapper">
    <Loading :isLoading="!isReady">
      <FaqClientPage v-if="componentType === 'pc'" ref="faqClient" />
      <FaqClientWindow v-if="componentType === 'sp'" ref="faqClient" />
      <div
        v-if="componentType === 'both'"
        ref="faqClient"
        class="FaqClientMultiple"
      >
        <FaqClientPage v-if="!$store.state.user.isSP" ref="faqClient" />
        <FaqClientWindow v-if="$store.state.user.isSP" ref="faqClient" />
      </div>
    </Loading>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import Loading from 'ptype/box/components/Loading.vue';
import FaqClientPage from 'ptype/box/components/FaqClientPage.vue';
import FaqClientWindow from 'ptype/box/components/FaqClientWindow.vue';
import { Watch } from 'vue-property-decorator';
import dataResource from '@/common/dataResource';
import search from '@/common/tagSearch';
import { getOnSiteSearchSettings } from '@/common/boxOnSiteSearchUtil';

@Component({
  components: {
    Loading,
    FaqClientPage,
    FaqClientWindow,
  },
})
export default class FaqClientWrapper extends Vue {
  componentType = process.env.TYPE;

  get isReady() {
    return this.$store.getters['isReady'];
  }

  get onSiteSearchButton() {
    return getOnSiteSearchSettings(
      this.$store.state.constObj.ON_SITE_SEARCH_BUTTON
    );
  }

  @Watch('isReady')
  async invokeInitialInputData() {
    if (!this.onSiteSearchButton.ENABLED) {
      return;
    }

    const urlSearchParams = new URLSearchParams(document.location.search);
    const tags = urlSearchParams.get('tags');
    const query = urlSearchParams.get('query');
    const category = urlSearchParams.get('category');

    await this.setInitialInputData({
      tags: tags && tags.split(','),
      query,
      category,
    });
  }
  async setInitialInputData({ tags, query, category }) {
    if (tags) {
      const values = await Promise.all(
        tags.map(tag => {
          return search.tagify(tag);
        })
      );
      await Promise.all(
        values.map((tag, i) => {
          const newTag = tag[0];
          newTag.displayText = tags[i];
          return this.$store.dispatch('tagSearch/addSelectedTag', newTag);
        })
      );
    }
    if (query) {
      await this.$store.dispatch('tagSearch/setSearchText', query);
    }
    if (category) {
      await dataResource.ready({ resourceName: 'talkScript' });
      const childCategory = dataResource.getItemSync({
        resourceName: 'talkScript',
        talkScriptId: category,
      });
      if (childCategory) {
        await this.$store.dispatch('tagSearch/addSelectedTag', {
          text: childCategory.text,
          displayText: `${childCategory.parent.text} > ${childCategory.text}`,
          type: 'node',
          id: childCategory.talkScriptId,
        });
      }
    }
    await this.$store.dispatch('tagSearch/updateFilteredItems', true);
  }
}
</script>

<style lang="scss" scoped>
.FaqClientMultiple {
  height: 100%;
}
</style>
