<template>
  <div class="loading-wrapper">
    <div
      v-if="isLoading"
      class="loadingArea"
      :class="{ mobile: isSP, withoutTitle: !$store.state.constObj.MAIN_TITLE }"
    >
      <img src="~product/resource/svg/sai-icon_loading.svg" />
    </div>
    <slot v-if="!isLoading" />
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import LoadingMixin from '@/mixins/LoadingMixin';

@Component({
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
})
export default class Loading extends mixins(LoadingMixin) {
  isSP = false;
  created() {
    const ua = window.navigator.userAgent;
    this.isSP = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      ua
    );
  }
}
</script>

<style lang="scss" scoped>
.loading-wrapper {
  height: 100%;
  width: 100%;

  .loadingArea {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 118px;

    &.withoutTitle {
      height: 79px;
    }

    img {
      width: 60px;
      height: 60px;
    }
  }

  .loadingArea.mobile {
    height: 101px;

    &.withoutTitle {
      height: 69px;
    }

    img {
      width: 50px;
      height: 50px;
    }
  }
}
</style>
