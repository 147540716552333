<template>
  <div class="sai-scenario">
    <div class="sai-scenario__caption">
      <div class="scenarioIcon">
        <img src="~product/resource/svg/sai-icon_click.svg" />
      </div>
      <div
        class="sai-caption"
        v-show="item && item.caption"
        v-html="item && item.caption"
      ></div>
    </div>
    <div class="sai-scenario__choices">
      <div
        class="sai-scenario__choices__choice"
        v-for="(choice, c_idx) in list"
        :key="c_idx"
      >
        <a
          :class="isSelected(choice.stepId) && 'selected'"
          @click="select(choice, index)"
        >
          {{ choice.text }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import dataResource from '@/common/dataResource';
import { eventBus } from '@/eventBus';
import { AsyncComputed } from '@/libs/vue-async-computed-decorator';
import { Watch } from 'vue-property-decorator';

@Component({
  props: {
    item: Object,
    index: Number,
  },
})
export default class Scenario extends Vue {
  selectedId = '';

  @Watch('selectedId')
  onChangeResultHeight() {
    setTimeout(() => {
      eventBus.$emit('updateRecommendHeight');
    }, 0);
  }
  @AsyncComputed()
  async list() {
    const list = await dataResource.getList(this.item);
    return list;
  }
  select(choice, index) {
    this.$emit('selectChoice', { choice, index });
    this.selectedId = choice.stepId;
  }
  isSelected(id) {
    return this.selectedId === id;
  }
}
</script>

<style lang="scss" scoped>
@import '../style/component/scenario';
</style>
