<template>
  <div
    class="FaqClientBox pc"
    :class="{ chrome: isChrome(), focus: show, withSaiModal: withModal }"
    ref="faqClientTag"
    id="sai-box"
  >
    <div class="wrapper">
      <div class="head" v-if="mainTitle">
        <div class="title">{{ mainTitle }}</div>
      </div>
      <div class="content">
        <div class="tags" v-if="withListOfKeywords">
          <a
            :class="['button', tagsShow && 'active']"
            @click="toggleChoices"
            ref="tagChoice"
          >
            <font-awesome-icon icon="list" />
            <span class="text" v-if="tagChoiceTitle">{{ tagChoiceTitle }}</span>
          </a>
          <div
            :class="`choices ${tagsShow && 'active'}`"
            :style="{
              height: `calc(100vh - 150px - ${tagChoiceTop}px - ${pcClientHeaderHeight}px)`,
            }"
          >
            <TagChoice
              v-if="tagsShow && Object.keys(topTags).length > 0"
              :topTags="topTags"
              :selectedTags="selectedTags.values()"
              @setShow="setTagSearchShow"
            ></TagChoice>
          </div>
        </div>
        <div :class="`search ${$store.state.tagSearch.show && 'active'}`">
          <div ref="taggedInput" class="tagInput" id="sai-tag-search-input">
            <TaggedInput
              ref="inputSearch"
              :selectedTags="selectedTags.values()"
              :searchText="searchText"
              :inputEmpty="inputEmpty"
              :filteredItems="filteredItems"
              @onTagRemoved="removeSelectedTag"
              @onSearchTextChanged="setSearchText"
              @setShow="setTagSearchShow"
            />
          </div>
          <div
            class="recommend"
            v-show="$store.state.tagSearch.show"
            :style="{
              height: `calc(100vh - 150px - ${inputSearchHeight}px - ${pcClientHeaderHeight}px)`,
            }"
          >
            <div ref="tagList">
              <TagList
                v-if="!inputEmpty || withInitRecommendArea1"
                :candidateTags="candidateTags"
                :tagListTop="tagListTop"
                :inputEmpty="inputEmpty"
                :searchText="searchText"
                @onSelectTag="addSelectedTag"
                @setShow="setTagSearchShow"
              />
            </div>
            <div
              class="autocomplete_header"
              v-show="!inputEmpty || withFrequentFaq"
            >
              <div class="title">{{ faqListTitle }}</div>
              <div class="search_result" v-if="!inputEmpty">
                <span class="count">{{ filteredItems.length }}</span>
                <span class="text">件ヒットしました</span>
              </div>
            </div>
            <div class="autocomplete" v-show="!inputEmpty">
              <Autocomplete
                :filteredItems="filteredItems"
                :noCandidateFaqMessage="noCandidateFaqMessage"
              />
            </div>
            <template v-if="withInitRecommendArea2 && withFrequentFaq">
              <div class="autocomplete" v-if="inputEmpty">
                <Frequent />
              </div>
            </template>
            <template v-if="withInitRecommendArea2 && withImageTags">
              <div class="sai-tag-categories" v-if="inputEmpty">
                <TagCategory
                  v-for="imgTag in imageTags"
                  :key="imgTag.index"
                  :title="imgTag.title"
                  :tags="imgTag.tags"
                  @tagifyText="tagifyTextExact"
                  @setShow="setTagSearchShow"
                />
              </div>
            </template>
            <div class="clearWrap">
              <a class="button" @click.prevent="setTagSearchShow(false)">
                <div class="icon close">
                  <font-awesome-icon icon="times" />
                </div>
                <span>{{ closeRecommendTitle }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="withModal">
      <div class="sai-tag-search-modal" ref="modal" v-show="show"></div>
    </template>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import FaqClientTagMixin from '@/mixins/FaqClientTagMixin';
import FaqClientTagBoxMixin from 'ptype/box/mixins/FaqClientTagBoxMixin';

@Component({})
export default class FaqClientPage extends mixins(
  FaqClientTagMixin,
  FaqClientTagBoxMixin
) {}
</script>

<style lang="scss" scoped>
@import '../style/component/faqClientPage';
</style>
