<template>
  <div class="sai-list-content">
    <ul v-if="filteredItems.length > 0" class="sai-list-content__lists">
      <FaqItem
        v-for="(item, index) in filteredItems"
        v-if="item"
        :key="item.talkScriptId"
        :item="item"
        :index="index"
        :action="'search'"
        :isKeyHighlighted="true"
      />
    </ul>
    <div v-else class="sai-list-content__none">
      <div class="sai-caption" v-html="noCandidateFaqMessage"></div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import FaqItem from 'ptype/box/components/FaqItem.vue';

@Component({
  props: {
    filteredItems: Array,
    noCandidateFaqMessage: String,
  },
  components: {
    FaqItem,
  },
})
export default class Autocomplete extends Vue {}
</script>

<style lang="scss" scoped>
.sai-list-content {
  &__none {
    padding: 10px 12px;
    @import '~ptype/box/style/utility/embedHtml';
  }
}
</style>
