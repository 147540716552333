<template>
  <div class="sai-list-content">
    <ul class="sai-list-content__lists">
      <FaqItem
        v-for="(item, index) in frequentItems"
        v-if="item"
        :key="item.talkScriptId"
        :item="item"
        :index="index"
        :action="'frequent'"
        :isKeyHighlighted="false"
      />
    </ul>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import frequent from '@/common/frequent';
import FaqItem from 'ptype/box/components/FaqItem.vue';

@Component({
  props: {},
  components: {
    FaqItem,
  },
})
export default class Frequent extends Vue {
  frequentItems = [];

  async created() {
    this.frequentItems = await frequent.getFaqList(
      process.env.INITIAL_FAQ_FILE
    );
  }
}
</script>

<style lang="scss" scoped></style>
