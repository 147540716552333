<template>
  <div :id="rootElementId">
    <div class="sai-tag-main">
      <FaqClientWrapper />
    </div>
  </div>
</template>

<script>
import FaqClientWrapper from 'ptype/box/components/FaqClientWrapper.vue';
import productTypeConst from 'ptype/box/const/index';
import productConst from 'product/const/index';
const Const = { ...productTypeConst, ...productConst };

export default {
  name: 'App',
  props: {
    rootElementId: String,
  },
  components: {
    FaqClientWrapper,
  },
  async created() {
    await this.$store.dispatch('Initialize', Const);
  },
};
</script>
