<template>
  <div class="resolveEnquete">
    <div>
      <div class="resolveButtonArea">
        <div class="buttonWrapper">
          <a
            :class="['resolveButton', showLastMessage && 'disabled']"
            @click.prevent="resolved"
          >
            {{ resolveMessage }}
          </a>
          <a
            :class="['resolveButton', showLastMessage && 'disabled']"
            @click.prevent="unresolved"
          >
            {{ unsolvedMessage }}
          </a>
        </div>
      </div>
    </div>
    <div id="sai-reset"></div>
    <div v-show="showLastMessage">
      <div class="lastMessage sai-caption" v-html="afterFeedbackMessage"></div>
      <div class="resolveButtonArea reset">
        <a @click.prevent="reset" class="resolveButton">{{ goToTopLabel }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import ResourceListFooterMixin from '@/mixins/ResourceListFooterMixin';
import { eventBus } from '@/eventBus';
import { Watch } from 'vue-property-decorator';

@Component({})
export default class ResourceListFooter extends mixins(
  ResourceListFooterMixin
) {
  @Watch('showLastMessage')
  onChangeResultHeight() {
    setTimeout(() => {
      eventBus.$emit('updateRecommendHeight');
    }, 0);
  }
  async resolved() {
    this.feedbackResult = 'resolved';
    this.setShowLastMessage(true);

    this.$store.commit('ticket/setResetFlag', true);
    const newTicket = this.updateTicket({
      item: this.currentValue,
      feedback: 'resolved',
    });
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
  async unresolved() {
    this.feedbackResult = 'unresolved';
    this.setShowLastMessage(true);

    this.$store.commit('ticket/setResetFlag', true);
    const newTicket = this.updateTicket({
      item: this.currentValue,
      feedback: 'unresolved',
    });
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
  async reset(e) {
    this.$emit('resetItem');
    if (this.$store.state.ticket.resetFlag) {
      eventBus.$emit('resetTicket');
      return;
    }
    const newTicket = this.generateClearTicket();
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
  initFeedbackData() {
    this.showLastMessage = false;
    this.feedbackResult = '';
  }
  created() {
    eventBus.$on('initFeedbackData', () => {
      this.initFeedbackData();
    });
  }
}
</script>

<style lang="scss" scoped>
@import '../style/component/resourceListFooter';
</style>
