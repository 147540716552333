<template>
  <div class="sai-item-result">
    <div
      class="sai-caption"
      v-show="item && item.caption"
      v-html="item && item.caption"
    ></div>
    <div class="feedbackArea" v-if="item.viewType === 'result'">
      <ResourceListFooter :currentValue="item" @resetItem="resetItem()" />
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import ResourceListFooter from 'ptype/box/components/ResourceListFooter.vue';

@Component({
  props: {
    item: Object,
  },
  components: {
    ResourceListFooter,
  },
})
export default class Result extends Vue {
  resetItem() {
    this.$emit('resetItem');
  }
}
</script>

<style lang="scss" scoped>
@import '~ptype/box/style/utility/embedHtml';
.sai-item-result {
  .sai-caption {
    padding-left: 6px;
  }
}
</style>
