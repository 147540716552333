<template>
  <div>
    <div class="sai-tagged-input" :class="{ onFocus: show }">
      <div class="sai-tagged-input__contents">
        <div v-if="show">
          <a
            class="sai-tagged-input__contents__tag"
            v-for="tag in selectedTags"
            :key="tag.id"
          >
            <span>{{ tag.displayText || tag.text }}</span>
            <a
              class="sai-tagged-input__contents__tag__close"
              @click.prevent="removeTag(tag)"
              ><font-awesome-icon icon="times" />
            </a>
          </a>
        </div>
        <input
          class="sai-tagged-input__contents__input"
          type="text"
          size="1"
          :value="searchText"
          :placeholder="placeholderMessage"
          @input="updateSearchText($event.target.value)"
          @focus="onFocus"
          @keyup="keyupHandler"
          @keydown="keydownHandler"
          @keypress="onKeypress"
          @compositionstart="onCompositionStart"
          @compositionend="onCompositionEnd"
          ref="searchInput"
        />
      </div>
      <div class="sai-tagged-input__functions" v-show="show">
        <div class="sai-tagged-input__functions__close">
          <a
            class="sai-tagged-input__functions__close__icon"
            @click.prevent="closeList"
          >
            <img src="@/resource/sai-icon_slim_x.png" />
          </a>
        </div>
        <div v-if="onSiteSearchButton.ENABLED">
          <a class="sai-tagged-input__functions__button" @click="transition">
            <span class="sai-tagged-input__functions__button__icon">
              <font-awesome-icon icon="search" />
            </span>
            <span
              v-if="buttonText && buttonText.length"
              class="sai-tagged-input__functions__button__text"
            >
              {{ buttonText }}
            </span>
          </a>
        </div>
      </div>
      <div class="sai-tagged-input__icon" v-show="!show">
        <font-awesome-icon icon="search" />
      </div>
    </div>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import TaggedInputMixin from '@/mixins/TaggedInputMixin';
import { eventBus } from '@/eventBus';
import search from '@/common/tagSearch';
import { getOnSiteSearchSettings } from '@/common/boxOnSiteSearchUtil';

@Component({})
export default class TaggedInput extends mixins(TaggedInputMixin) {
  componentType = process.env.TYPE;

  // Use setShow for box
  setShow(show) {
    this.$emit('setShow', show);
  }

  get onSiteSearchButton() {
    return getOnSiteSearchSettings(
      this.$store.state.constObj.ON_SITE_SEARCH_BUTTON
    );
  }

  get buttonText() {
    if (
      (this.$store.state.user.isSP && this.componentType === 'both') ||
      this.componentType === 'sp'
    ) {
      return this.$store.state.constObj.ON_SITE_SEARCH_BUTTON_SP_TEXT;
    }

    return this.$store.state.constObj.ON_SITE_SEARCH_BUTTON_PC_TEXT;
  }

  async transition() {
    if (!this.onSiteSearchButton.ENABLED) {
      return;
    }

    const ticketService = this.$ticket;
    const ticketPkey = ticketService.ticket
      ? ticketService.ticket.partitionKey
      : null;
    const ticketRkey = ticketService.ticket
      ? ticketService.ticket.rangeKey
      : null;
    const query = this.$store.state.tagSearch.searchText || '';
    const queries = query
      .trim()
      .split(/[ 　]+/)
      .filter(q => q.length > 0);
    const tags = this.$store.state.tagSearch.selectedTags
      .values()
      .map(({ text, displayText }) => displayText || text);
    const wordList = tags.concat(queries);

    const fullTextQuery = await search.buildFullTextSearchQuery(wordList);

    const submitUrl = new URL(this.onSiteSearchButton.SEARCH_URL);
    submitUrl.searchParams.set('primary_key', ticketPkey);
    submitUrl.searchParams.set('secondary_key', ticketRkey);
    submitUrl.searchParams.set(
      'full_text_query',
      JSON.stringify(fullTextQuery)
    );
    submitUrl.searchParams.set('tags', tags.join(','));
    submitUrl.searchParams.set('query', query);

    // Send ticket before transit
    const baseValues = this.updateOpenTicket();
    const siteSearchValues = {
      'action_out-of-service': {
        type: 'siteSearch',
        value: submitUrl.href,
      },
    };
    const newTicket = { ...baseValues, ...siteSearchValues };
    await this.$ticket.setData(newTicket);
    await this.$ticket.post(); // await: Prevent cancel

    location.href = submitUrl.href;
  }

  async keydownHandler(e) {
    const exKeyCode = this.keydownCode;
    this.keydownCode = e.keyCode;
    this.isComposing = e.isComposing;
    if (this.notContinuousSpace(exKeyCode, e.keyCode) && this.searchText) {
      const newTicket = this.updateEnterTicket();
      await this.$ticket.setData(newTicket);
      await this.$ticket.post();
      return;
    }
    if (this.tagRemoveCondition(e)) {
      await this.$store.dispatch('tagSearch/removeLastTag');
      if (this.$store.state.ticket.resetFlag) {
        eventBus.$emit('resetTicket');
        return;
      }
      const newTicket = this.updateOpenTicket();
      await this.$ticket.setData(newTicket);
      this.$ticket.post();
    }
    if (this.onlyEnterKey(e)) {
      this.transition();
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../style/component/taggedInput';
</style>
