<template>
  <li class="sai-list" :class="selected && 'selected'">
    <div
      class="sai-faq-item"
      :class="index === 0 && 'first'"
      :style="{ animationDelay: `${index < 20 ? 0.05 * (index + 1) : 0}s` }"
      @click="selectItem(item)"
    >
      <div class="sai-faq-item__wrapper">
        <div class="sai-faq-item__wrapper__content">
          <div class="sai-faq-item__wrapper__content__ancesters">
            <span
              class="sai-faq-item__wrapper__content__ancesters__ancester"
              v-for="(ancester, index) in item.ancesters"
              :key="index"
            >
              {{ ancester.text }}
              <span v-if="index < item.ancesters.length - 1">&gt;</span>
            </span>
          </div>
          <div class="sai-faq-item__wrapper__content__title">
            <div class="sai-faq-item__wrapper__content__title__text">
              <template v-if="isKeyHighlighted">
                <Highlight :text="item.text" :targets="tagAndSearchKeys" />
              </template>
              <template v-else>{{ item.text }}</template>
            </div>
          </div>
        </div>
      </div>
      <div class="sai-faq-item__wrapper sp">
        <div class="sai-faq-item__wrapper__content">
          <div class="sai-faq-item__wrapper__content__ancesters">
            <span
              class="sai-faq-item__wrapper__content__ancesters__ancester"
              v-for="(ancester, index) in item.ancesters"
              :key="index"
            >
              {{ ancester.text }}
              <span v-if="index < item.ancesters.length - 1">&gt;</span>
            </span>
          </div>
        </div>
        <div class="sai-faq-item__wrapper__content__title">
          <div class="sai-faq-item__wrapper__content__title__text">
            <template v-if="isKeyHighlighted">
              <Highlight :text="item.text" :targets="tagAndSearchKeys" />
            </template>
            <template v-else>{{ item.text }}</template>
          </div>
        </div>
      </div>
      <div class="sai-faq-item__icon" v-if="selected">
        <font-awesome-icon icon="chevron-right" />
      </div>
    </div>
    <div class="sai-box-result" v-show="selected">
      <div
        class="sai-box-result__content"
        v-if="item.viewType === 'scenario'"
        v-for="(scenario, s_idx) in scenarios"
        :key="s_idx"
      >
        <Scenario
          v-if="scenario.viewType === 'scenario'"
          :item="scenario"
          :index="s_idx"
          @selectChoice="selectChoice"
        />
        <Result
          v-if="scenario.viewType === 'result'"
          :item="scenario"
          @resetItem="resetItem"
        />
      </div>
      <div class="sai-box-result__content" v-if="item.viewType === 'result'">
        <Result :item="item" @resetItem="resetItem" />
      </div>
    </div>
  </li>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { eventBus } from '@/eventBus';
import { Watch } from 'vue-property-decorator';
import { AsyncComputed } from '@/libs/vue-async-computed-decorator';
import dataResource from '@/common/dataResource';
import search from '@/common/tagSearch';
import Scenario from 'ptype/box/components/Scenario.vue';
import Result from 'ptype/box/components/Result.vue';
import Highlight from 'ptype/box/components/Highlight.vue';

@Component({
  props: {
    item: Object,
    index: Number,
    action: String,
    isKeyHighlighted: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Scenario,
    Result,
    Highlight,
  },
})
export default class FaqItem extends Vue {
  selected = false;
  scenarios = [];

  get isResult() {
    return this.item.viewType === 'result';
  }

  @AsyncComputed()
  async tagAndSearchKeys() {
    if (!this.isKeyHighlighted) {
      return [];
    }

    let keys = this.$store.getters['tagSearch/selectedKeywordTags'].map(t => {
      return t.displayText || t.text;
    });

    if (this.$store.state.tagSearch.searchText) {
      // Split search text by whitespace and ignore trailing whitespace
      let inputWords = this.$store.state.tagSearch.searchText.match(/\S+/g);
      if (inputWords) {
        keys.push(...inputWords);
      }
    }

    const synonymWords = await search.buildFullTextSearchQuery(keys);
    const allKeys = [].concat(...synonymWords);

    // Sort the keys array base on key length to make sure that longer words will take the precedence to be highlighted (e.g. tickets > ticket)
    allKeys.sort((a, b) => b.length - a.length);

    return allKeys;
  }

  @Watch('selected')
  onChangeResultHeight() {
    eventBus.$emit('initFeedbackData');
    setTimeout(() => {
      eventBus.$emit('updateRecommendHeight');
    }, 0);
  }

  @Watch('scenarios')
  onChangeScenarios() {
    eventBus.$emit('initFeedbackData');
  }

  async selectItem(item) {
    if (this.$store.state.user.isIphone && this.$store.state.onIME) {
      eventBus.$emit('toggleIme', false);
      return;
    }

    if (this.selected) {
      this.resetItem();
      this.publishOpenTicket();
      return;
    }

    // Page transition
    const isPageTransition = await this.transitionItem(item);
    if (isPageTransition) {
      this.$store.commit('tagSearch/setShow', false);
      await this.publishFaqTicket(item);
      await this.movePage(item);
      return;
    }

    // Open selected item
    if (item.resourceName === 'scenario') {
      const scenario = await dataResource.getItem(item);
      Object.assign(item.items, scenario.items);
      this.scenarios.push(scenario);
    }
    this.selected = true;
    this.publishFaqTicket(item);
  }
  resetItem() {
    this.selected = false;
    this.scenarios = [];
  }
  transitionItem(item) {
    const reg = /^http|^https/;
    return item.talkScriptId && item.caption.match(reg);
  }
  movePage(item) {
    window.location.href = item.caption;
  }
  async publishFaqTicket(item) {
    this.$store.commit('ticket/setResetFlag', true);
    const newTicket = await this.updateFaqTicket({
      item: item,
      faqAction: this.action,
    });
    await this.$ticket.setData(newTicket);
    await this.$ticket.post();
  }
  updateFaqTicket({ item, faqAction }) {
    const startTime =
      this.$store.state.ticket.startTime || String(new Date().getTime());
    this.$store.commit('ticket/setStartTime', startTime);
    const historyAction = { type: '', value: faqAction };
    this.$store.commit('ticket/addHistoryActionFaqChannel', historyAction);
    const keywordTags = this.$store.getters['tagSearch/selectedKeywordTags'];
    const tagActiveSet =
      keywordTags.length === 0
        ? []
        : keywordTags.map(t => {
            const v = { id: t.id, text: t.text };
            if (t.displayText) {
              v.label = t.displayText;
            }
            return v;
          });
    if (
      keywordTags.length > 0 &&
      this.$store.state.ticket.tagUsedSet.length === 0
    ) {
      this.$store.commit('ticket/setTagUsedSet', tagActiveSet);
    }
    const query = this.$store.state.tagSearch.searchText;
    if (query && this.$store.state.ticket.historyQuery.length === 0) {
      this.$store.commit('ticket/setHistoryQuery', [query]);
    }
    return this.generateFaqTicket(item, startTime, query, tagActiveSet);
  }
  generateFaqTicket(item, startTime, query, tagActiveSet) {
    const commonTicket = {
      origin: 'window',
      user_agent: this.$store.state.user.userAgent,
      user_id: this.$store.state.user.Id,
      product_type: this.$store.state.productType,
      start_time: startTime,
      end_time: String(new Date().getTime()),
      query: query,
      tag_active_set: tagActiveSet,
      tag_used_set: this.$store.state.ticket.tagUsedSet,
      history_query: this.$store.state.ticket.historyQuery,
      history_action_faq_channel: this.$store.state.ticket
        .historyActionFaqChannel,
    };

    let newTicket = {
      ...commonTicket,
      status: item.viewType === 'result' ? 'answered' : 'answering',
      items: item.items,
    };
    if (item.viewType === 'result') {
      newTicket.status_feedback = 'open';
    }

    return newTicket;
  }
  async publishOpenTicket() {
    if (this.$store.state.ticket.resetFlag) {
      eventBus.$emit('resetTicket');
      return;
    }
    const newTicket = await this.updateOpenTicket();
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
  updateOpenTicket() {
    const startTime =
      this.$store.state.ticket.startTime || String(new Date().getTime());
    this.$store.commit('ticket/setStartTime', startTime);
    const keywordTags = this.$store.getters['tagSearch/selectedKeywordTags'];
    const tagActiveSet =
      keywordTags.length === 0
        ? []
        : keywordTags.map(t => {
            const v = { id: t.id, text: t.text };
            if (t.displayText) {
              v.label = t.displayText;
            }
            return v;
          });
    if (
      keywordTags.length > 0 &&
      this.$store.state.ticket.tagUsedSet.length === 0
    ) {
      this.$store.commit('ticket/setTagUsedSet', tagActiveSet);
    }
    const query = this.$store.state.tagSearch.searchText;
    if (query && this.$store.state.ticket.historyQuery.length === 0) {
      this.$store.commit('ticket/setHistoryQuery', [query]);
    }
    return this.generateOpenTicket(startTime, query, tagActiveSet);
  }
  generateOpenTicket(startTime, query, tagActiveSet) {
    const newTicket = {
      origin: 'window',
      user_agent: this.$store.state.user.userAgent,
      user_id: this.$store.state.user.Id,
      product_type: this.$store.state.productType,
      start_time: startTime,
      end_time: String(new Date().getTime()),
      query: query,
      tag_active_set: tagActiveSet,
      tag_used_set: this.$store.state.ticket.tagUsedSet,
      history_query: this.$store.state.ticket.historyQuery,
      history_action_faq_channel: this.$store.state.ticket
        .historyActionFaqChannel,
      status: 'open',
    };
    return newTicket;
  }
  selectChoice({ choice, index }) {
    // Insert or replace the next index
    this.scenarios.splice(index + 1);
    this.scenarios.push(choice);
    this.publishFaqTicket(choice);
  }
}
</script>

<style lang="scss" scoped>
@import '../style/component/faqItem';
</style>
