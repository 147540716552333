import Vue from 'vue';
import AsyncComputed from 'vue-async-computed-non-debounce';
import VueScrollTo from 'vue-scrollto';
import '@/libs/emulateScroll';
import { SAITicketService } from '@sciseed/sai-ticket/dist/sai-ticket.common.js';

import App from 'ptype/box/App';
import store from '@/store/index';
import 'ptype/box/style/index.scss';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons/faRedoAlt';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faTag } from '@fortawesome/free-solid-svg-icons/faTag';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons/faUserSecret';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export const init = ({ rootElementId }) => {
  // Call needed modules
  Vue.use(AsyncComputed);
  Vue.config.productionTip = false;
  const ticketConfig = {
    ticketInterval: 1800,
  };
  Vue.use(SAITicketService, {
    config: ticketConfig,
    url: process.env.TICKET_URL,
    productId: process.env.RESOURCE_ID,
  });

  // Add font to library and add font-awesome to global components
  library.add(
    faList,
    faChevronRight,
    faChevronLeft,
    faSearch,
    faSignOutAlt,
    faRedoAlt,
    faTimes,
    faTag,
    faUserSecret,
    faPlus
  );
  Vue.component('font-awesome-icon', FontAwesomeIcon);

  // Call vue-scroll
  const scrollConfig = {
    duration: 500,
    easing: 'ease',
    offset: 0,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: true,
    y: true,
  };
  Vue.use(VueScrollTo, scrollConfig);

  // Setting of Vue instance
  const id = rootElementId || 'sai-search-box-app';
  const vueSetting = {
    el: `#${id}`,
    store: store,
    render: h => h(App, { props: { rootElementId: id } }),
  };

  /* eslint-disable no-new */
  new Vue(vueSetting);
};
